<template>
  <div>
    <!-- 申请试用 -->
    <div class="scan-code-switch-container apply" @click="handleEnterApply">
      <div class="scan-code-switch d-flex flex-center">
        <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-mini/apply-try.png" alt="">
      </div>
      <div class="txt" :class="activeIndex ==1 ||  activeIndex ==7? 'cor-white' : 'cor-theme'">申请试用</div>
    </div>
    <!-- 关注我们 -->
    <div class="scan-code-switch-container follow-us"  @mouseenter="handleIsFollow(true)" @mouseleave="handleIsFollow(false)">
      <div class="scan-code-switch d-flex flex-center">
        <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/08.png" alt="">
      </div>
      <div class="txt" :class="activeIndex ==1 ||  activeIndex ==7? 'cor-white' : 'cor-theme'">关注我们</div>
    </div>
    <!-- 联系我们 -->
    <div class="scan-code-switch-container link" @mouseenter="handleIsFollow1(true)" @mouseleave="handleIsFollow1(false)">
      <div class="scan-code-switch d-flex flex-center">
        <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-Static/WeMeet-mini/qiwei-logo.png" alt="" style="transform:scale(1.5) translateX(1.5px)">
      </div>
      <div class="txt" :class="activeIndex ==1 ||  activeIndex ==7? 'cor-white' : 'cor-theme'">联系我们</div>
    </div>
  </div>
</template>
<script>
export default {
  name:'sideNav',
  props:{
    activeIndex:{
      type: Number,
      default: 0
    }
  },
  components:{},
  data() {
    return {}
  },
  methods: {
    handleEnterApply() {
      window.location.href = "https://v.wemeet.com/#/formData?code=Q1768463872009699328"
    },
    handleIsFollow(bool) {
     this.$emit("handleIsFollow",bool)
    },
    handleIsFollow1(bool) {
      this.$emit("handleIsFollow1",bool)
    },
  },
  mounted() {}
}
</script>
<style scoped>
.cor-theme {
  color: #00C4C9;
}
.cor-white {
  color: #fff;
}
.scan-code-switch-container1,.scan-code-switch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1300;
  right: 8px;
  cursor: pointer;
}
.apply {
  bottom: 245px;
}
.follow-us {
  bottom: 185px;
}
.link {
  bottom: 125px;
}

@media screen and (max-width: 768px)  {
  .scan-code-switch-container {
    /* bottom: 18vh; */
    right: 8px;
  }
}
 .scan-code-switch {
  display: flex;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
}
.scan-code-switch img {
  display: block;
  width: 17px;
  height: 17px;
}

 .txt {
  font-size: 8px;
  margin-top: 2px;
  cursor: pointer;
  text-wrap: nowrap;
}
@media (min-width: 768px) and (max-width: 991px) {
  .txt {
    font-size: 9px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .txt {
  font-size: 10px;
}
}
@media (min-width: 1200px) {
  /* 在这里写入对大于 1200px 宽度的 CSS 样式 */
  .txt {
  font-size: 12px;
}
}
</style>