<template>
  <div class="swiper-container">
    <swiper :options="swiperOption" ref="homeSwiper"   @mouseenter.native="mouseEnter"
          @mouseleave.native="mouseLeave"
>
      <swiper-slide v-for="(item, index) in bannersData" :key="index">
        <template v-if="index == 0">
          <div class="swiper-box box1">
            <div class="text">全球商务互联新平台 企业数字营销新阵地</div>
            <img class="box-img"
              src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/pc-01.png" alt="">
          </div>
        </template>
        <!-- PC -->
        <div v-if="index != 0" class="swiper-box box2">
          <div class="left">
            <div class="top">
              <template v-if="item.alias == 'wemeet' || item.alias == 'mehz'">
                <img :src="item.pc.logo">
              </template>
              <p class="p1">{{ item.title }}</p>
              <p class="p2">{{ item.name }}</p>
            </div>
            <div class="bottom">
              <img :src="item.pc.scanCodeImg" alt="">
              <p class="p1">{{ item.desc }}</p>
            </div>
          </div>
          <div class="right">
            <div class="bg-container">
              <div class="bg"></div>
              <img :src="item.pc.img" alt="">
            </div>
          </div>
        </div>
        <!-- h5 -->
        <div v-if="index != 0" class="swiper-box-h5">
          <div class="left">
            <div class="top">
              <template v-if="item.alias == 'wemeet' || item.alias == 'mehz'">
                <img :src="item.h5.logo">
              </template>
              <p class="p1">{{ item.title }}</p>
              <p class="p2">{{ item.name }}</p>
            </div>
            <div class="bottom">
              <img :src="item.h5.scanCodeImg" alt="">
              <p class="p1">{{ item.desc }}</p>
            </div>
          </div>
          <div class="right">
            <div class="bg-container">
              <div class="bg"></div>
              <img :src="item.h5.img" alt="">
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
export default {
  name: 'homeCarousel',
  props: {},
  components: {},
  data() {
    return {
      isShow: false,
      bannersData: [
        {
          id: 1,
          pc: {
            img: '',
            scanCodeImg: '',
            logo: '',
          },
          h5: {
            img: '',
            scanCodeImg: '',
            logo: '',
          },
          title: '商务互联新平台',
          name: '“WeMeet”',
          alias: 'index',
          desc: '扫码试用'
        },
        {
          id: 2,
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/01.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/05.png',
            logo: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/wemmetLogo1.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/13.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/05.png',
            logo: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/wemmetLogo1.png',
          },
          title: '商务互联新平台',
          name: '“WeMeet”',
          alias: 'wemeet',
          desc: '扫码试用'
        },
        {
          id: 3,
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/01.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/01.png',
            logo: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/mehzLogo1.png',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/01.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/01.png',
            logo: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/mehzLogo1.png',
          },
          title: '城市平台',
          name: '“ME杭州”',
          alias: 'mehz',
          desc: '了解更多'
        },
        {
          id: 4,
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/03.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/02.png',
            logo: '',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/02.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/02.png',
            logo: '',
          },
          title: '机构',
          name: '“深圳国际会展中心”',
          alias: 'organization',
          desc: '了解更多'
        },
        {
          id: 5,
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/04.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/03.png',
            logo: '',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/03.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/03.png',
            logo: '',
          },
          title: '展会',
          name: '“国际产业合作大会(新加坡)暨中国机电产品品牌展览会”',
          alias: 'exhibition',
          desc: '了解更多'
        },
        {
          id: 6,
          pc: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/05.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/04.png',
            logo: '',
          },
          h5: {
            img: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/H5/04.png',
            scanCodeImg: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/scanCode/04.png',
            logo: '',
          },
          title: '大型会议',
          name: '“AIPC亚洲峰会”',
          alias: 'aipc',
          desc: '了解更多'
        },

      ],
      // 轮播配置
      swiperOption: {
        autoplay: {
          delay: 6000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false,
        },
        initialSlide: 0,
        preventClicks: true,
        hideOnClick: false,
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'my-bullet',// 一般
          bulletActiveClass: 'my-bullet-active',// 选中
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        onTransitionStart(swiper) {
          console.log(swiper)
        },
        on: {
          click: (e) => { },
          slideChangeTransitionStart: (e) => {
            console.log(":eeee", e)
            setTimeout(() => {
              this.activeIndex = this.swiper.activeIndex
              this.$emit("sendActiveIndex",this.activeIndex)
            });
          },
        },
      },
      activeIndex: 0,
    }
  },
  computed: {
    swiper() {
      return this.$refs.homeSwiper.swiper
    }
  },
  methods: {
    goPage(item) {
      console.log("item===", item)
    },
    mouseEnter() {
      this.swiper.autoplay.stop()
    },
    mouseLeave() {
      this.swiper.autoplay.start();
    },
  },
  mounted() {
    // this.$nextTick(()=>{
    //   console.log(this.swiper.autoplay.stop())
    // })
  }
}
</script>
<style scoped>
.swiper-container {
  width: 100vw;
  height: 86vh;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.swiper-container .swiper-slide {
  height: 86vh;
  width: 100% !important;
  position: relative;
}

.swiper-container .swiper-slide .swiper-box img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 第一张轮播图 */
.swiper-container .swiper-slide .box1 {
  width: 100%;
  height: 100%;
  background-color: #00c4c9;
  position: relative;
}

.swiper-container .swiper-slide .box1 .box-img {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 34vw;
  height: 33vw;
}

.swiper-container .swiper-slide .box1 .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  width: 320px;
  height: 84px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  line-height: 42px;
  z-index: 100;
  font-weight: 600;
  text-align: center;
}

/* 第二张轮播图 */
.swiper-container .swiper-slide .box2 {
  width: 100%;
  height: 100%;
  display: flex;
}

/* 左边 */
.swiper-container .swiper-slide .box2 .left {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 41.67vw;
  height: 100%;
  background: #f2f2f2;
  /* padding-top: 30px; */
  /* padding-top: 159px;
    padding-bottom: 150px; */
}

.swiper-container .swiper-slide .box2 .left .top {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.swiper-container .swiper-slide .box2 .left .top img {
  width: 110px;
  height: 110px;
}

.swiper-container .swiper-slide .box2 .left .top .p1 {
  font-size: 36px;
  color: #262626;
  font-weight: 600;
  line-height: 50px;
  margin-top: 20px;
}

.swiper-container .swiper-slide .box2 .left .top .p2 {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 26px;
  font-weight: 600;
  font-size: 36px;
  color: #262626;
}

.swiper-container .swiper-slide .box2 .left .bottom {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-top: 70px;
}

.swiper-container .swiper-slide .box2 .left .bottom img {
  width: 270px;
  height: 270px;
}

.swiper-container .swiper-slide .box2 .left .bottom .p1 {
  margin-top: 12px;
  font-size: 24px;
  color: #262626;
}

/* 右边 */
.swiper-container .swiper-slide .box2 .right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58.33vw;
  height: 100%;
}

.box2 .right .bg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.box2 .right .bg-container .bg {
  width: 38vw;
  height: 38vw;
  border-radius: 50%;
  background-color: #00c4c9;
}

.box2 .right .bg-container img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.3);
  height: 72%;
}

.swiper-container .swiper-slide .swiper-button-prev {
  z-index: 1000;
}

.swiper-container .swiper-slide .swiper-button-next {
  z-index: 1000;
}

.swiper-pagination {
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
}

/* 媒体查询 */
@media screen and (min-width: 320px) and (max-width: 480px) {
  .swiper-container {
    min-height: 86vh;
  }

  .swiper-container .swiper-slide {
    min-height: 86vh;
  }

  .swiper-container .swiper-slide .swiper-box {
    min-height: 86vh;
  }

  .swiper-container .swiper-slide .swiper-box-h5 {
    min-height: 86vh;
  }

  .swiper-container .swiper-slide .box1 .box-img {
    width: 34vh;
    height: 33vh;
  }

  .swiper-container .swiper-slide .box1 .text {
    width: 302px;
    height: 84px;
    font-size: 32px;

    line-height: 42px;
    z-index: 100;
  }
}

/* 针对h5端的布局开始 */
@media screen and (max-width: 480px) {
  .swiper-container .swiper-slide .box2 {
    display: none;
  }

  .swiper-container .swiper-slide .swiper-box-h5 {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    /* padding: 10px 0; */
    box-sizing: border-box;
  }

  .swiper-box-h5 .left {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    min-height: 43vh;
    padding: 10px 0 0;
    box-sizing: border-box;
    background-color: #f2f2f2;
  }

  .swiper-box-h5 .right {
    width: 100%;
    min-height: 43vh;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    background-color: #fff;
    flex: 1;
  }

  .swiper-box-h5 .right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .swiper-box-h5 .left .top {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }

  .swiper-box-h5 .left .top img {
    width: 50px;
    height: 50px;
  }

  .swiper-box-h5 .left .top .p1 {
    font-size: 17px;
    color: #262626;
    font-weight:600;
    margin-top: 10px;
  }

  .swiper-box-h5 .left .top .p2 {
    width: 245px;
    font-weight: 600;
    font-size: 17px;
    color: #262626;
    text-align: center;
  }

  .swiper-box-h5 .left .bottom {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-top: 3%;
  }

  .swiper-box-h5 .left .bottom img {
    width: 110px;
    height: 110px;
  }

  .swiper-box-h5 .left .bottom .p1 {
    font-size: 12px;
    color: #262626;
    margin-top: 6px;
  }

  .swiper-box-h5 .right .bg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 217px;
    height: 100%;
    position: relative;
  }

  .swiper-box-h5 .right .bg-container .bg {
    width: 217px;
    height: 217px;
    border-radius: 50%;
    background-color: #00c4c9;
  }

  .swiper-box-h5 .right .bg-container img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.4);
    width: 174px;
    height: 261px;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: -14px !important;
  }
}

/* 针对h5端的布局开始 */

@media screen and (min-width: 480px) {
  .swiper-container {
    min-height: 90vh;
  }

  .swiper-container .swiper-slide {
    min-height: 90vh;
  }

  .swiper-container .swiper-slide .swiper-box {
    min-height: 90vh;
  }

  .swiper-container .swiper-slide .box1 .text {
    width: 429px;
    height: 245px;
    font-size: 42px;
    color: #ffffff;
    line-height: 90px;
    z-index: 100;
  }
  .swiper-container .swiper-slide .box2 .left .top .p1 {
    font-size: 27px;
  }

  /* .box2 .right .bg-container  img {
    height: 62vh;
    width:27vw;
  } */
  /* 第二张轮播图 */
  .swiper-container .swiper-slide .box2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }

  .swiper-container .swiper-slide .swiper-box-h5 {
    display: none;
  }

  .box2 .right .bg-container img {
    height: 100%;
    transform: translate(-50%, -50%) scale(1);
  }

  /* 左边 */
  .swiper-container .swiper-slide .box2 .left .top .p1 {
    font-size: 27px;
  }

  .swiper-container .swiper-slide .box2 .left .top .p2 {
    margin-top: 26px;
    font-weight: 600;
    font-size: 30px;
    color: #262626;
  }

  .swiper-container .swiper-slide .box2 .left .bottom {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-top: 50px;
  }

  .swiper-container .swiper-slide .box2 .left .bottom img {
    width: 188px;
    height: 188px;
  }

  .swiper-container .swiper-slide .box2 .left .bottom .p1 {
    margin-top: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #262626;
  }
}

@media screen and (min-width: 540px) {
  .swiper-container .swiper-slide .box1 .text {
    width: 533px;
    height: 200px;
    transform: translate(-50%, -50%);
    font-size: 52px;
    color: #ffffff;
    z-index: 100;
  }

  .box2 .right .bg-container .bg {
    width: 40vw;
    height: 40vw;
    border-radius: 50%;
  }

  .box2 .right .bg-container img {
    height: 100%;
  }
}

@media screen and (min-width: 720px) and (max-width: 750px) {
  .swiper-container .swiper-slide .box1 .text {
    width: 616px;
    height: 245px;
    font-size: 62px;
    color: #ffffff;
    z-index: 100;
  }

  .box2 .right .bg-container img {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@media screen and (min-width: 768px) {
  .swiper-container .swiper-slide .box1 .text {
    width: 732px;
    height: 245px;
    font-size: 72px;
    color: #ffffff;
    line-height: 116px;
    z-index: 100;
  }

  .box2 .right .bg-container img {
    height: 100%;
  }

  .swiper-container .swiper-slide .box2 .left .bottom img {
    width: 270px;
    height: 270px;
  }
}

@media screen and (min-width: 992px) {
  .swiper-container .swiper-slide .box1 .text {
    width: 833px;
    height: 245px;
    font-size: 82px;
    line-height: 122px;
    letter-spacing: 9px;
    color: #ffffff;
    z-index: 100;
  }

  .box2 .right .bg-container img {
    height: 100%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1596px) {

  .box2 .right .bg-container img {
    width: 45vw;
  }
}

@media screen and (min-width: 1596px) {

  .box2 .right .bg-container img {
    width: 35vw;
  }

  .box2 .right .bg-container .bg {
    width: 36vw;
    height: 36vw;
  }
}

@media screen and (min-width: 2500px) {

  .box2 .right .bg-container img {
    width: 30vw;
  }

  .box2 .right .bg-container .bg {
    width: 28vw;
    height: 28vw;
  }
}

/* @media screen and (min-width: 1200px) and (max-width: 1596px) {

  .box2 .right .bg-container img {
    transform: translate(-50%, -50%) scale(1);
    height: 100%;
  }
} */



.swiper-pagination /deep/ .my-bullet {
  width: 8px;
  height: 6px;
  border-radius: 6px;
  margin-left: 4px;
  background: #B5FDFF;
  display: block;
}

.swiper-pagination /deep/ .my-bullet-active {
  width: 20px;
  height: 6px;
  border-radius: 6px;
  margin-left: 4px;
  display: block;
  background: #63DDE1;
}</style>