<template>
  <div class="home-header-container">
    <div class="left">
      <img src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeet-official-website/PC/wemeetLogo.png" alt="" @click="handleJumpIndex">
    </div>
    <div class="right d-flex flex-center" @click="handleJumpPage">{{ type == 'home' ? '立即体验' : '首页' }}</div>
  </div>
</template>
<script>
export default {
  name:'homeHeader',
  props:{
    type: {
      type: String,
      default: "home",
    }
  },
  components:{},
  data() {
    return {}
  },
  methods: {
    handleJumpPage() {
      let type = this.type 
      if(type == 'home') {
        this.$router.push("/linkUs")
      } else {
        this.$router.push("/")
      }
    },
    handleJumpIndex() {
      this.$router.push("/")
    }
  },
  mounted() {}
}
</script>
<style scoped lang="less">
@media screen and (max-width: 320px) { 
  .home-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 6vh;
    padding: 0 10px 0 10px;
    // box-sizing: border-box;
    .left {
      img {
        display: block;
        width: 115px;
        height: 28px;
        cursor: pointer;
      }
    }
    .right {
      padding: 6px 10px 5px;
      box-sizing: border-box;
      line-height: 1;
      color: #00C4C9;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #00C4C9;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) { 
  .home-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 6vh;
    padding: 0 10px 0 10px;
    .left {
      img {
        display: block;
        width: 115px;
        height: 28px;
        cursor: pointer;
      }
    }
    .right {
      padding: 6px 10px 5px;
      box-sizing: border-box;
      line-height: 1;
      color: #00C4C9;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #00C4C9;
      cursor: pointer;
    }
  }
}
@media screen and  (min-width: 480px) { 
  .home-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 5vh;
    padding: 0 25px 0 20px;
    .left {
      img {
        display: block;
        width: 130px;
        height: 36px;
        cursor: pointer;
      }
    }
    .right {
      padding: 6px 14px;
      box-sizing: border-box;
      line-height: 1;
      color: #00C4C9;
      font-size: 18px;
      border-radius: 6px;
      border: 1px solid #00C4C9;
      cursor: pointer;
    }
  }
}

</style>