import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("@/views/aboutUs/index.vue"),
  },
  {
    path: "/joinUs",
    name: "joinUs",
    component: () => import("@/views/joinUs/index.vue"),
  },
  {
    path: "/linkUs",
    name: "linkUs",
    component: () => import("@/views/linkUs/index.vue"),
  },
  {
    path: "/liveServer",
    name: "liveServer",
    component: () => import("@/views/liveServer/index.vue"),
  },
  {
    path: "/liveDetail",
    name: "liveDetail",
    component: () => import("@/views/liveServer/detail.vue"),
  },
  {
    path: "/newsCenter",
    name: "newsCenter",
    component: () => import("@/views/newsCenter/index.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () => import("@/views/newsCenter/detail.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register.vue"),
  },
  
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { y: 0 };
    }
  },
});

export default router;
